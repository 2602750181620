export const wrapper = ({ theme, backgroundColor }) => ({
    backgroundColor: backgroundColor || theme.colors.white,

    textAlign: 'center',

    overflow: 'hidden',

    '& .Heading1': {
        marginBottom: '2rem',
        fontWeight: '800 !important',
        letterSpacing: -1.6,
    },

    '& p': {
        letterSpacing: -1,
        fontWeight: 400,
        marginBottom: '5rem',
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '42rem',
    },

    '& section': {
        paddingBottom: '5.5rem',
    },
});

export const subTitle = theme => ({
    maxWidth: '48rem',
    min768px: {
        fontSize: '2.1875rem', // 30px
        lineHeight: '3.125rem', // 48px
    },
});
