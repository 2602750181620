import React from 'react';
import PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { Row, Col } from 'antd/lib/grid';

import Section from '../Section';

import Feature, { featureShape } from '../Feature';

import * as componentStyles from './FeaturesBigSection.style';
import Heading1 from '../../Heading1';
import Text from '../../Text';
import { TextSize } from '../../Text/Text.style';

const FeaturesBigSection = ({ features, backgroundColor }) => (
    <FelaComponent style={componentStyles.wrapper} backgroundColor={backgroundColor}>
        <Section id="big-features">
            <Heading1>
                <FormattedMessage id="event.bigFeatures.title" />
            </Heading1>
            <Text size={TextSize.BIG} overrideStyles={componentStyles.subTitle}>
                <FormattedMessage id="event.bigFeatures.text" />
            </Text>
            <Row type="flex">
                {features.map((feature, i) => (
                    <Col key={i} xs={24} sm={24} md={12} xl={8}>
                        <Feature isGatsbyImage={true} withMargin={true} {...feature} />
                    </Col>
                ))}
            </Row>
        </Section>
    </FelaComponent>
);

FeaturesBigSection.propTypes = {
    features: PropTypes.arrayOf(featureShape),
    backgroundColor: PropTypes.string,
};

FeaturesBigSection.defaultProps = {
    features: [],
    backgroundColor: undefined,
};

export default FeaturesBigSection;
