const image = {
    display: 'inline',
    width: '18rem',
    maxWidth: '100%',
    height: '32rem',
    margin: '2rem 0',
    borderRadius: '1rem',
    boxShadow: `-5px 9px 30px 0px rgba(0,0,0,0.2)`,
    max1390px: {
        min768px: {
            boxShadow: `-4px 6px 15px 0px rgba(0,0,0,0.2)`,
        },
    },
};

export default ({ withMargin, isGatsbyImage }) => ({
    ...(withMargin && {
        marginTop: '1rem',
        marginBottom: '4rem',
    }),
    ...((isGatsbyImage && {
        '& .gatsby-image-wrapper': {
            ...image,
            max480px: {
                transform: 'scale(0.85)',
            },
        },
    }) || {
        '& img': {
            ...image,
        },
    }),
    '& span': {
        opacity: 0.8,
    },
    '& .Heading3': {
        fontSize: '1.375rem',
    },
});
