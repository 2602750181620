import React from 'react';
import PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';

import SEO from './../components/SEO';
import ContactsSection from './../components/Event/ContactsSection';
import Footer from '../components/Footer';
import FeaturesBigSection from './../components/Event/FeaturesBigSection';
import HeaderScroller from '../components/HeaderScroller';
import { LinkType } from '../components/Link/Link.style';
import { default as felaTheme } from '../../src/styles/theme';

const links = [
    { to: '/', labelId: 'home.menu.top', type: LinkType.GATSBY },
    { to: '/features', labelId: 'home.menu.features', type: LinkType.GATSBY },
    { to: '/festival', labelId: 'home.menu.festival', type: LinkType.GATSBY },
    { to: '/conference', labelId: 'home.menu.conference', type: LinkType.GATSBY },
    { to: '#contacts', labelId: 'home.menu.contacts', asButton: true, type: LinkType.NATIVE },
];

const backgroundColor = felaTheme.colors.greyLight;

const mainStyle = ({ theme }) => ({
    paddingTop: '6rem',
    backgroundColor,
});

const FeaturesBase = ({ features }) => (
    <>
        <SEO title={`title.features`} desc={`description.features`} />
        <HeaderScroller links={links} inBlackStyle={true} hasHeader={false} />
        <FelaComponent style={mainStyle} as={'main'}>
            <FeaturesBigSection features={features} backgroundColor={backgroundColor} />
            <ContactsSection titleId={`home.contacts.title`} />
        </FelaComponent>
        <Footer />
    </>
);

FeaturesBase.propTypes = {
    features: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default FeaturesBase;
