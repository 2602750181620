export default ({ theme, color }) => ({
    padding: '0 1rem',

    fontSize: '1.5rem', // 24px
    fontWeight: '700',
    lineHeight: '2rem', // 32px

    color: theme.colors[color] || color,

    min768px: {
        lineHeight: '3.125rem', // 50px
    },
});
