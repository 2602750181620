import React from 'react';
import PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import Img from 'gatsby-image';

import Heading3 from '../../Heading3';
import Text from '../../Text';

import style from './Feature.style';

const propTypes = {
    titleId: PropTypes.string.isRequired,
    descId: PropTypes.string.isRequired,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    withMargin: PropTypes.bool,
    isGatsbyImage: PropTypes.bool,
};

const defaultProps = {
    withMargin: false,
    isGatsbyImage: false,
};

export const featureShape = PropTypes.shape(propTypes);

const Feature = ({ titleId, descId, image, withMargin, isGatsbyImage }) => (
    <FelaComponent style={style} withMargin={withMargin} isGatsbyImage={isGatsbyImage}>
        {!isGatsbyImage && <img src={image} alt="" role="presentation" />}
        {isGatsbyImage && <Img fixed={image} alt="" fadeIn />}
        <Heading3>
            <FormattedMessage id={titleId} />
        </Heading3>
        <Text size="small" color="black">
            <FormattedHTMLMessage tagName="span" id={descId} />
        </Text>
    </FelaComponent>
);

Feature.propTypes = propTypes;
Feature.defaultProps = defaultProps;

export default Feature;
