import React from 'react';
import PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';

import style from './Heading3.style';

const Heading3 = ({ color, element, ...props }) => (
    <FelaComponent color={color} style={style}>
        {({ className }) =>
            React.createElement(
                element,
                { ...props, className: `Heading3 ${props.className} ${className}` },
                props.children,
            )
        }
    </FelaComponent>
);

Heading3.propSizes = {
    className: PropTypes.string,
    color: PropTypes.string,
    element: PropTypes.string,
};

Heading3.defaultProps = {
    className: '',
    color: 'black',
    element: 'h3',
};

export default Heading3;
