import { graphql, useStaticQuery } from 'gatsby';

const useFeatures = () => {
    const { allFile } = useStaticQuery(graphql`
        {
            allFile(
                filter: { extension: { regex: "/png$/" }, relativeDirectory: { eq: "features-page" } }
                sort: { fields: name, order: ASC }
            ) {
                edges {
                    node {
                        name
                        childImageSharp {
                            fixed(width: 324, height: 576) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
            }
        }
    `);
    return allFile.edges.map((item, index) => ({
        titleId: `event.features.${index + 1}.title`,
        descId: `event.features.${index + 1}.desc`,
        image: item.node.childImageSharp.fixed,
    }));
};

export default useFeatures;
