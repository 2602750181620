import React from 'react';
import FeaturesBase from '../pageComponents/FeaturesBase';
import withPageContext from '../pageContext/withPageContext';
import useFeatures from '../components/Features/useFeatures';

const Features = () => {
    const features = useFeatures();
    return <FeaturesBase features={features} />;
};

export default withPageContext(Features);
